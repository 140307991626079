"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { useAppSelector } from "@hooks";
import { useTranslation } from "i18n/client";

import { Box } from "@mui/material";
import { AuthTemplate } from "@components/templates";
import { CustomTypography } from "@components/atoms";
import { AuthSocialButtons, LoginFormFooterText } from "@components/molecules";
import { LoginForm } from "@components/organisms";

export const Login = () => {
  const { loggedIn } = useAppSelector((state) => state.auth);
  const router = useRouter();
  loggedIn && router.push("/home");
  const { t } = useTranslation("auth");
  return (
    <AuthTemplate>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            marginBottom: "24px",
          }}
        >
          <CustomTypography
            textalign="center"
            fontstyle="normal"
            fontsize="30px"
            fontweight="600"
            lineheight="38px"
            color="#101828"
          >
            {t("Login.subtitle")}
          </CustomTypography>
        </Box>
        <LoginForm />
        <AuthSocialButtons />
        <LoginFormFooterText />
      </Box>
    </AuthTemplate>
  );
};
